<template>
  <body style="position: relative;">
  <div id="wrap">
		<section>
			<headerLayout></headerLayout>
		</section>
    <main>
        <section class="sub-visual">
            <div class="innWrap">
                <div class="txt">
                    <h2>센터 안내</h2>
                    <!-- <p>GiGA 네트워크는 물론 랙호스팅, 운용대행까지 ICT Total Care 서비스</p> -->
                </div>
                <div class="location">
                    <ul>
                        <li><a href="#">PRODUCTS</a></li>
                        <li><a href="#">GiGAoffice</a></li>
                        <li><span>센터안내</span></li>
                    </ul>
                </div>
            </div>
        </section>
        <section class="sub-section center-giga">
            <div class="innWrap">
                <h3>기가오피스 센터 안내</h3>
                <div class="dotList">
                    <ul>
                        <li>출입 요청방법 : 해당 GiGA office센터 메일주소로 사전 출입요청</li>
                        <li>센터 운영 : 업무시간(평일 09:00~18:00)에 방문해주시기 바라며, 긴급출동의 경우 고장(장애)접수 후 방문하시면 원활한 업무 진행이 가능합니다.</li>
                    </ul>
                </div>

                <h3 class="mgt55">전국 기가오피스 센터 위치</h3>
                <div class="dotList">
                    <ul>
                      <li>- 정렬순서 : 서울 > 경기 > 인천 > 그외도시 주소로 정렬 (같은 도시안에서는 주소로 정렬입니다)</li>
                    </ul>
                </div>
                <table class="tbl_type02 mgt35">
                    <colgroup>
                        <col style="width: 15%;">
                        <col style="width: 35%;">
                        <col style="width: 15%;">
                        <col style="width: 35%;">
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope="col">구분</th>
                            <th scope="col">주소</th>
                            <th scope="col">구분</th>
                            <th scope="col">주소</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>강서</td>
                            <td>서울특별시 강서구 화곡동 893-1 3층<br>
                            kangseo.gigaoffice@kt.com</td>
                            <td>구로</td>
                            <td>서울특별시 관악구 신림동 1660 3층<br>
                            guro.gigaoffice@kt.com</td>
                        </tr>
                        <tr>
                            <td>아현</td>
                            <td>서울특별시 서대문구 충정로3가 35-1 2층<br>
                            ahyeon.gigaoffice@kt.com</td>
                            <td>서초</td>
                            <td>서울특별시 서초구 방배동 1001-1 1층<br>
                            seocho.gigaoffice@kt.com</td>

                        </tr>
                        <tr>
                            <td>행당</td>
                            <td>서울특별시 성동구 행당동 318-26 1층<br>
                            haengdang.gigaoffice@kt.com</td>
                            <td>가락</td>
                            <td>서울특별시 송파구 가락동 77-6 4층<br>
                            garak.gigaoffice@kt.com</td>
                        </tr>
                        <tr>
                            <td>혜화</td>
                            <td>서울특별시 종로구 연건동 128-9 1층<br>
                            hyehwa.gigaoffice@kt.com</td>
                            <td>일산</td>
                            <td>경기도 고양시 일산서구 대화동 2200 2층<br>
                            ilsan.gigaoffice@kt.com</td>
                        </tr>
                        <tr>
                            <td>동판교</td>
                            <td>경기도 성남시 분당구 삼평동 730 2층<br>
                            pangyo.gigaoffice@kt.com</td>
                            <td>모란</td>
                            <td>경기도 성남시 수정구 수진동 4004 3층<br>
                            moran.gigaoffice@kt.com</td>

                        </tr>
                        <tr>
                            <td>수원</td>
                            <td>경기도 수원시 장안구 영화동 306-1 2층<br>
                            suwon.gigaoffice@kt.com</td>
                            <td>안산</td>
                            <td>경기도 안산시 단원구 고잔동 521 3층<br>
                            ansan.gigaoffice@kt.com</td>

                        </tr>
                        <tr>
                            <td>안양</td>
                            <td>경기도 안양시 동안구 비산동 1110-2 3층<br>
                            anyang.gigaoffice@kt.com</td>
                            <td>신갈</td>
                            <td>경기도 용인시 기흥구 구갈동 415 2층<br>
                            singal.gigaoffice@kt.com</td>

                        </tr>
                        <tr>
                            <td>동의정부</td>
                            <td>경기도 의정부시 민락동 697 4층<br>
                            donguijeongbu.gigaoffice@kt.com</td>
                            <td>평택</td>
                            <td>경기도 평택시 비전동 251-15 5층<br>
                            pyeongtek.gigaoffice@kt.com</td>

                        </tr>
                        <tr>
                            <td>인천</td>
                            <td>인천광역시 남동구 간석동 520-3 4층<br>
                            incheon.gigaoffice@kt.com</td>
                            <td>서인천</td>
                            <td>인천광역시 서구 심곡동 252 2층<br>
                            seoincheon.gigaoffice@kt.com</td>
                        </tr>
                        <tr>
                            <td>동강릉</td>
                            <td>강원도 강릉시 강릉대로 442 (포남동 1294)<br>
                            donggangneung.gigaoffice@kt.com</td>
                            <td>원주</td>
                            <td>강원도 원주시 학성동 902-2 3층<br>
                            wonju.gigaoffice@kt.com</td>

                        </tr>
                        <tr>
                            <td>춘천</td>
                            <td>강원도 춘천시 금강로 11 (소양로3가 175-5)<br>
                            chuncheon.gigaoffice@kt.com</td>
                            <td>서안동</td>
                            <td>경북 안동시 안기천로 86 (당북동 48-7)<br>
                            seoandong.gigaoffice@kt.com</td>
                        </tr>
                        <tr>
                            <td>동마산</td>
                            <td>경상남도 마산시 구암동 84-1 2층<br>
                            dongmasan.gigaoffice@kt.com</td>
                            <td>구미</td>
                            <td>경상북도 구미시 송정동 48번지 3층<br>
                            gumi.gigaoffice@kt.com</td>
                        </tr>
                        <tr>
                            <td>포항</td>
                            <td>경상북도 포항시 남구 대도동 135-19 2층<br>
                            pohang.gigaoffice@kt.com</td>
                            <td>북광주</td>
                            <td>광주광역시 북구 신안동 16-1 3층<br>
                            bukgwangju.gigaoffice@kt.com</td>
                        </tr>
                        <tr>
                            <td>동대구</td>
                            <td>대구광역시 동구 신암동 249-7 3층<br>
                            dongdaegu.gigaoffice@kt.com</td>
                            <td>대전</td>
                            <td>대전광역시 중구 문화동 1-30<br>
                            daejeon.gigaoffice@kt.com</td>
                        </tr>
                        <tr>
                            <td>부산</td>
                            <td>부산광역시 중구 중앙동4가 75-1 2층<br>
                            busan.gigaoffice@kt.com</td>
                            <td>울산</td>
                            <td>울산광역시 중구 반구동 24-11 1층<br>
                            ulsan.gigaoffice@kt.com</td>
                        </tr>
                        <tr>
                            <td>목포</td>
                            <td>전라남도 목포시 옥암동 980<br>
                            mokpo.gigaoffice@kt.com</td>
                            <td>북순천</td>
                            <td>전라남도 순천시 석현동 87 2층<br>
                            buksuncheon.gigaoffice@kt.com</td>
                        </tr>
                        <tr>
                            <td>군산</td>
                            <td>전라북도 군산시 나운동 765번지<br>
                            gunsan.gigaoffice@kt.com</td>
                            <td>북전주</td>
                            <td>전라북도 전주시 덕진구 덕진동1가 1274-1 1층<br>
                            bukjeonju.gigaoffice@kt.com</td>
                        </tr>
                        <tr>
                            <td>제주</td>
                            <td>제주시 연동 321-23 1층<br>
                            jeju.gigaoffice@kt.com</td>
                            <td>남천안</td>
                            <td>충청남도 천안시 쌍용동 387-4 3층<br>
                            cheonan.gigaoffice@kt.com</td>
                        </tr>
                        <tr>
                            <td>홍성</td>
                            <td>충청남도 홍성군 홍성읍 오관리 115-1 5층<br>
                            hongseong.gigaoffice@kt.com</td>
                            <td>남청주</td>
                            <td>충청북도 청주시 흥덕구 분평동 1390 5층<br>
                            namcheongju.gigaoffice@kt.com</td>
                        </tr>
                        <tr>
                            <td>충주</td>
                            <td>충청북도 충주시 성내동 282번지 1층<br>
                            chungju.gigaoffice@kt.com</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    </main>
    <section>
      <footerLayout></footerLayout>
    </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'

export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data () {
    return {
    }
  },
  created: function () {

  },
  mounted: function () {
  },
  methods: {
    clickTp: function () {
    }
  }
}
</script>